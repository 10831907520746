/* Animation */
.animate-slide {
    animation: slide 10s linear infinite;
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

/* 3D Box Shadow Effect */
.transform-3d {
    
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1),
        0 25px 50px rgba(0, 0, 0, 0.1),
        0 35px 75px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.transform-3d:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1),
        0 30px 60px rgba(0, 0, 0, 0.15),
        0 40px 90px rgba(0, 0, 0, 0.2);
}

/* Add other necessary styles for text, fonts, colors, etc. */
.text-[#262626] {
    color: #262626;
}

.text-opacity-100 {
    opacity: 1;
}